<template>
  <div class="flex items-center flex-col">
    <div class="flex justify-start lg:w-1/2 mb-2">
      <a-button @click="$router.back()">
        Regresar
      </a-button>
    </div>
    <form
      class="lg:w-1/2"
      @submit.prevent="isUpdating ? update(administrator) : store(administrator)"
      @keydown="removeError($event.target.id)">

      <h3 class="text-2xl my-4">
        {{ isUpdating ? 'Actualizar' : 'Crear' }} administrador - <span class="text-xl inline-block">Información personal</span>
      </h3>

      <div class="flex flex-col">
        <div class="flex flex-col items-center space-y-2">
          <div class="w-28 h-28 rounded-full bg-gray-100">
            <img class="w-full h-full rounded-full" :src="picture || administrator.profile_picture" v-if="picture || administrator.profile_picture" alt="profile_picture">
          </div>
          <div class="flex justify-center space-x-2">
            <transition-group name="fade" mode="out-in">
              <a-button class="mr-2" scheme="default" borderless key="0">
                <label for="file_input" class="flex space-x-2 items-center text-primary">
                  <a-icon name="upload" />
                  <span>Seleccionar imagen</span>
                  <input type="file" class="hidden" id="file_input" @change="handlePhotoSelection">
                </label>
              </a-button>
              <a-button @click="dropPhoto" v-if="picture || administrator.profile_picture" key="1">
              <span class="flex space-x-2 items-center">
                <a-icon name="trash" class="text-red-500" />
                <span>Eliminar</span>
              </span>
              </a-button>
            </transition-group>
          </div>
        </div>

        <a-input
          class="my-4"
          required
          label="Nombres"
          id="name"
          placeholder="Nombres"
          :error="error('name', { attribute: 'nombres' })"
          v-model="administrator.name" />

        <a-input
          label="Correo electrónico"
          required
          id="email"
          class="mb-4"
          placeholder="Correo electrónico"
          :error="error('email', { attribute: 'email' })"
          v-model="administrator.email"/>

        <a-select
          label="Tipo"
          required
          :source="[{ label: 'Asistente', value: 4 }, { label: 'Administrador', value: 5 }]"
          :error="error('type', { attribute: 'tipo' })"
          v-model="administrator.type" />
      </div>

      <div class="flex space-x-2 mt-4">
        <a-button mode="primary" type="submit" :loading="loading">
          {{ isUpdating ? 'Actualizar cambios' : 'Guardar cambios' }}
        </a-button>

        <a-button outlined :to="{ name: 'administrators' }">
          Cancelar
        </a-button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex'
export default {
  data: () => ({
    picture: null,
    isUpdating: false,
    administrator: {
      type: 5
    }
  }),
  computed: {
    ...mapGetters({
      hasError: 'hasError',
      error: 'error'
    }),
    ...mapState({
      administrators: state => state.administrators.pagination.data,
      currentAdministratorOnState: state => state.administrators.administrator,
      loading: state => state.administrators.loading
    })
  },
  methods: {
    ...mapMutations({
      setNavbarTitle: 'SET_NAVBAR_TITLE',
      setAdministrator: 'administrators/SET_ADMINISTRATOR'
    }),
    ...mapActions({
      store: 'administrators/store',
      removeError: 'removeError',
      fetchAdministrator: 'administrators/show',
      update: 'administrators/update',
      updateProfilePicture: 'administrators/updateProfilePicture'
    }),
    handlePhotoSelection(event) {
      const reader = new FileReader()
      reader.onload = () => {
        this.administrator.profile_picture = event.target.files[0]
        this.picture = reader.result
      }
      reader.readAsDataURL(event.target.files[0])
    },
    dropPhoto() {
      this.administrator.profile_picture = null
      this.picture = null
      document.querySelector('#file_input').value = null
    },
    handlePhotoUpdate() {
      if (this.picture) {
        this.updateProfilePicture(this.administrator.profile_picture)
      }
    }
  },
  watch: {
    currentAdministratorOnState(val) {
      delete val.email
      this.administrator = {...val}
    }
  },
  created() {
    const id = this.$route.query.update
    this.setNavbarTitle(['Listado de administradores', 'Crear administrador'])
    if (id) {
      this.isUpdating = true

      const found = this.administrators.find(administrator => administrator.id === parseInt(id))
      this.setAdministrator(found)
      if (found) {
        delete found.email
        this.administrator = {...found}
        this.setNavbarTitle(['Listado de administradores', `${found.first_name} ${found.last_name}`, 'Editar perfil'])
      } else {
        this.fetchAdministrator(id)
      }
    }
  }
}
</script>
