<template>
<div>
    <div class="mb-2">
            <a-button @click="$router.back()">
            Regresar
        </a-button>
    </div>
    <div class="flex -mx-2 flex-wrap justify-center">
        <div class="w-1/2 lg:w-2/3 xl:w-2/5 px-2">
            <a-card v-if="administrator">
                <div class="flex items-center flex-col justify-center truncate">
                    <a-avatar :text="administrator.name" :picture="administrator.profile_picture" size="100" class="mb-4"/>

                    <p class="font-bold text-lg truncate">
                        {{ `${administrator.name}` }}
                    </p>
                    <p class="truncate">
                        {{ administrator.phone }}
                    </p>
                    <p>
                        {{ administrator.email }}
                    </p>
                </div>
                <div class="flex flex-wrap justify-between mt-4">
                    <p class="text-center">
                        Ultimo inicio de sesión: <br>
                        <b>{{ administrator.last_login_at | date('yyyy-MM-DD', 'Nunca') }}</b>
                    </p>
                    <p class="text-center">
                        Creado el: <br>
                        <b>{{ administrator.created_at | date }}</b>
                    </p>
                </div>
            </a-card>
            <a-card v-else>
                <div class="text-center">
                    <p class="mb-4">
                        <a-skeleton circle height="100px" width="100px"/>
                    </p>
                    <a-skeleton />
                    <a-skeleton />

                    <div class="flex justify-between -mx-2 flex-wrap mt-4">
                        <a-skeleton class="w-1/2 px-2" />
                        <a-skeleton class="w-1/2 px-2" />
                    </div>
                </div>
            </a-card>
        </div>
    </div>
</div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
    methods: mapActions({
        show: 'administrators/show'
    }),
    computed: mapState({
        administrator: ({ administrators }) => administrators.administrator
    }),
    mounted() {
        this.show({ id: this.$route.params.id })
    }
}
</script>